<template>
  <!-- <div>车辆授权</div> -->
	<el-container class="VehicleAuthorization">
		<el-header height="auto">
			<el-row>
				<el-col :span="19">
					<el-row>
						<el-col :span="4">
							<span class="headerInfo">登录账户：</span>
							{{customer.name}}
						</el-col>
						<el-col :span="3">
							<span class="headerInfo">类型：</span>
							{{['我的客户', '监控客户'][customer.accountType]}}
						</el-col>
            <el-col :span="4">
							<span class="headerInfo">授权币：</span>
							{{customer.money}}
						</el-col>
						<el-col :span="13">
							<span class="headerInfo">设备数量：</span>
							<span class="markerSpan" style="background-color:#1890FF;" ></span>
							全部：{{customer.allVehicleCount}} 台
							<span class="markerSpan" style="background-color:#1EDF8C;margin-left:25px;"></span>
							在线：{{customer.allOnlineCount}} 台
							<span class="markerSpan" style="background-color:#FF4640;margin-left:25px;"></span>
							离线：{{customer.allOffLineCount}} 台
						</el-col>
					</el-row>
				</el-col>
				<el-col :span="5" class="rightCol">
					<el-tooltip
						effect="dark"
						content="操作说明"
						placement="bottom-end"
					><span>
							<a
								href="/resource/车辆授权文档.docx"
								style="font-size:14px;color:#878787"
							>
								<i class="el-icon-question"></i>
								授权操作说明书
							</a>
						</span>
					</el-tooltip>
				</el-col>
			</el-row>
		</el-header>
		<el-main>
			<el-tabs>
				<el-tab-pane label="车辆授权">
					<!-- 车辆授权 -->
					<CarEmpower></CarEmpower>
				</el-tab-pane>
				<el-tab-pane label="授权记录">
					<!-- 授权记录 -->
					<CarRecord></CarRecord>
				</el-tab-pane>
				<el-tab-pane label="授权币划拨">
					<!-- 授权币划拨 -->
					<CarSpendMoney
						@UserInfo="UserInfo"
					></CarSpendMoney>
				</el-tab-pane>
				<el-tab-pane label="授权币划拨记录">
					<!-- 授权币划拨记录 -->
					<CarSpendMoneyRecord
						:userId="userID"
					></CarSpendMoneyRecord>
				</el-tab-pane>
			</el-tabs>
		</el-main>
	</el-container>
</template>

<script>
//车辆授权进入的授权页面
import CarEmpower from "./CarEmpower";
//授权记录
import CarRecord from "./CarRecord";
//授权币划拨
import CarSpendMoney from "./CarSpendMoney";
//授权币划拨记录
import CarSpendMoneyRecord from "./CarSpendMoneyRecord";
//用户和车辆的情况
import {addUpVehicleStatusCount,queryMyState} from "@/api/All-Interface.js";

export default {
  // `name`与路由保持一致
  name: 'VehicleAuthorization',
	data(){
		return{
			customer: {
        allOffLineCount: 0,
        allOnlineCount: 0,
        allVehicleCount: 0,
        money: 0,
        name: '',
        accountType: 0
      },
	  	userID:"",
		}
	},
	components:{
		CarEmpower,
		CarRecord,
		CarSpendMoney,
		CarSpendMoneyRecord,
	},
	methods:{
		UserInfo(){
			addUpVehicleStatusCount().then((res) => {
				if (res.flag == 1) {
          this.customer.allOffLineCount = res.obj.allOffLineCount;
          this.customer.allOnlineCount = res.obj.allOnlineCount;
          this.customer.allVehicleCount = res.obj.allVehicleCount;
        } else {
          this.$message.error(res.msg);
        };
			});
			queryMyState().then((res) =>{
				if (res.flag == 1) {
          this.customer.money = res.obj.money.money;
          this.customer.name = res.obj.money.name;
					this.userID = res.obj.money.userId;
        } else {
          this.$message.error(res.msg);
        };
			});
		},
	},
	mounted(){
		this.UserInfo();
	}
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
.VehicleAuthorization{
	height: 100%;
	.filter-pane > label{ //调整头部组件间的间隔距离
		margin: 0 6px 0 14px;
	}
	.oper-btns{
		position: absolute;
		bottom: 24px;
	}
	.el-header{
		margin: 0;
		font-size: 14px;
		padding: 10px 0;
		.headerInfo{
			font-weight: bold;	
		}
		.el-col {
			text-align: left;
			padding-left: 5px;
		}
		.rightCol{
			text-align: right;
			padding-right: 5px;
		}
	}
	.el-main{
		height: calc(100% - 37px);
		 .el-tabs{
			height: 100%;
			::v-deep .el-tabs__content{
				height: calc(100% - 45px);
				.el-tab-pane{
					height: 100%;
				}
			}
		}
	}
	.markerSpan{
		height: 5px;
		width: 5px;
		background-color: red;
		border-radius: 50%;
		display:inline-block;
		margin: 0 5px;
		position: relative;
		top: -2px;
	}
}
</style>