<template>
  <!-- <div>车辆授权组件</div> -->
  <el-container class="CarContainer">
    <el-header height="auto">
      <div class="filter-pane">
        <el-button @click="queryVehicleExpired(7,2,1)">即将到期7天</el-button>
        <el-button @click="queryVehicleExpired(30,2,1)">即将到期30天</el-button>
        <el-button @click="overdueCar">到期车辆</el-button>
        <el-button type="primary" @click="CheckRecharge(1)">搜索条件</el-button>
        <el-button type="primary" @click="CheckRecharge(2)">自定义授权</el-button>
        <el-button type="primary" @click="CheckRecharge(3)">批量授权</el-button>
      </div>
      <div class="oper-btns">
        <BtnExport
          style="width:90px;height:32px"
          :disabled="!data.length>0"
          title="车辆授权"
          exportUrl="excelExport/exportExcel.do"
          exportDataType="1"
          :params="{
						type:0,
            ...searchParams,
            startTime:this.searchParams.accBeginTime,
            endTime:this.searchParams.accEndTime,
            pageSize:5000,
          }"
        />
      </div>
    </el-header>
    <el-main>
      <el-table
        v-loading="tableLoading"
        height="99%"
        size="mini"
        :data="data"
        @selection-change="changeTableData"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="所属车组" prop="groupName" width="120"></el-table-column>
        <el-table-column label="车牌" prop="plate" width="120"></el-table-column>
        <el-table-column label="终端编号" prop="terminalNo" width="120"></el-table-column>
        <el-table-column label="激活时间" width="180" prop="activationTme"></el-table-column>
        <el-table-column label="状态" prop="state" width="80">
          <template slot-scope="scope">
            <el-tag type="danger" v-text="scope.row.state == 1?'过期':'正常'"></el-tag>
          </template>
        </el-table-column>
        <el-table-column label="车辆状态" prop="vehicState"></el-table-column>
        <el-table-column label="终端类型" prop="terType"></el-table-column>
        <el-table-column label="安装时间" prop="installDate"></el-table-column>
        <el-table-column label="到期时间" prop="expireDate"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="recharge(scope)">授权</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-pagination
      background
      :current-page="tablePage.pageNumber"
      :page-size="tablePage.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tablePage.total"
      @current-change="changePage"
    ></el-pagination>
    <!-- 进入页面时弹窗 -->
    <div class="TipsDialog reportDialog">
      <el-dialog
        title="温馨提示"
        :visible.sync="Tips"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="true"
      >
        <div class="tips">
          <p>
            1.授权币
            <span>划拨</span>后将不可撤回，请谨慎操作！
          </p>
          <p>
            2.车辆
            <span>授权</span>后将不可撤回，请谨慎操作！
          </p>
          <p>
            3.服务到期前
            <span>删除车辆</span>，授权币将不予退回！
          </p>
          <p>
            4.部标类设备
            <span>3</span>个授权币=
            <span>3</span>月，一次性授权1年优惠后只需
            <span>10</span>个授权币！
          </p>
          <p>
            5.视频类设备
            <span>10</span>个授权币=
            <span>3</span>月，一次性授权1年优惠后只需
            <span>25</span>个授权币！
          </p>
          <p>
            6.
            <span>设备上线后才开始计算到期时间！</span>
          </p>
          <el-button type="primary" @click="Tips = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 授权弹窗 -->
    <CarAuthCoin
      :dialogVisible="dialogVisible"
      @input="closeDialog"
      :vehicleList="vehicleList"
    ></CarAuthCoin>

    <!-- 列表行内点击授权 -->
    <Recharge
      :RechargeModel="RechargeModel"
      :Cardata="RechargeData"
      @close="closeRecharge"
    ></Recharge>
    <!-- 批量授权 -->
    <LotRecharge
      :RechargeModel="LotRechargeModel"
      :Cardata="LotRechargeData"
      @close="LotcloseRecharge"
    ></LotRecharge>
    <!-- 自定义授权 -->
    <DiyRecharge
      :RechargeModel="DiyRechargeModel"
      :Cardata="DiyRechargeData"
      @close="DiycloseRecharge"
    ></DiyRecharge>
    <!-- 搜索条件 -->
    <SearchCriteria
      :RechargeModel="SearchModel"
      :Cardata="SearchData"
      @close="closeSearchCriteria"
      @SearchData="DataList"
    ></SearchCriteria>
  </el-container>
</template>

<script>
//搜索接口
import { queryVehicleExpired } from '@/api/All-Interface.js';
//点击授权时，弹出授权框
import Recharge from '@/views/VehicleAuthorization/Recharge';
//批量授权
import LotRecharge from '@/views/VehicleAuthorization/LotRecharge';
//自定义授权
import DiyRecharge from '@/views/VehicleAuthorization/DiyRecharge';
//搜索条件
import SearchCriteria from '@/views/VehicleAuthorization/SearchCriteria';
//导出接口
import BtnExport from "@/components/Export";

// 授权弹窗
import CarAuthCoin from "@/views/VehicleAuthorization/CarAuthCoin";


export default {
  // `name`与路由保持一致
  name: 'CarEmpower',
  data() {
    return {
      searchParams: this.clearSearchParams(),
      tableLoading: false,//table 加载数据的转圈圈
      data: [],//table 数据集合
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 50,
        total: 0,
        flag:0,
        days:0,
      },
      //点击授权的子组件
      RechargeModel: false,//开启子组件弹窗，默认关闭
      RechargeData: {},//传给子组件数据
      //批量授权的子组件
      LotRechargeModel: false,//开启子组件弹窗，默认关闭
      LotRechargeData: [],//传给子组件数据
      //自定义授权的子组件
      DiyRechargeModel: false,//开启子组件弹窗，默认关闭
      DiyRechargeData: {},//传给子组件数据
      //搜索条件的子组件
      SearchModel: false,//开启搜索条件弹窗，默认关闭
      SearchData: '',//搜索条件数据

      //进入时温馨提示弹窗
      Tips: false,

      //授权弹窗开关
      dialogVisible:false,
      vehicleList:[],// 授权数据集合
    };
  },
  components: {
    Recharge,//点击授权
    LotRecharge,//批量授权
    DiyRecharge,//自定义授权
    SearchCriteria,//搜索条件
    BtnExport,//导出按钮

    CarAuthCoin,//授权弹窗
  },
  methods: {
    //分页改变每页数量
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.queryVehicleExpired(this.tablePage.days,this.tablePage.flag,current);
    },
    //进入时搜索
    async queryVehicleExpired(number, flag = 0,current) {//参数：到期天数、查询状态、页码
      if (number) {
        this.searchParams = this.clearSearchParams();//初始化搜索参数
        this.searchParams.expireTime = number * 24 * 60 * 60;
      }
      this.searchParams.pageNumber = current;
      this.tablePage.pageNumber = current;
      //用于分页记录过期时间和查询状态
      this.tablePage.days = number;
      this.tablePage.flag = flag;
      //搜索参数获取过期时间和查询状态
      this.searchParams.days = number;
      this.searchParams.flag = flag;
      this.tableLoading = true;//开启加载数据的转圈圈
      try {
        const result = await queryVehicleExpired(this.searchParams);
        this.tableLoading = false;//关闭加载数据的转圈圈
        this.data = result.rows;
        this.tablePage.total = result.total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    //点击到期车辆
    overdueCar() {
      this.searchParams = this.clearSearchParams();//初始化搜索参数
      this.queryVehicleExpired(0, 1, 1);
    },
    //初始化 搜索参数 
    clearSearchParams() {
      return {//接口请求参数
        expireTime: 2678400,//到期时间
        groupId: '0',//车组ID
        isState: '',//状态  1：过期，0：正常
        vehicleId: '0',//车辆id
        vehicleState: '',//车辆状态
        activationTmeEnd: '',//终端活动结束时间
        activationTmeStart: '',//终端活动开始时间
        installDateEnd: '',//安装结束时间
        installDateStart: '',//安装开始时间
        offlineTime: '0',//离线时间
        pageSize: '50',//页大小
        pageNumber: '1',//页码
        flag: 0,//0:全部; 1:查询过期车辆; 2:查询即将过期车辆
        days: 0,//询即将过期车辆时,按多少天的标准为即将过期
      };
    },
    //批量授权选择行时候
    changeTableData(selection) {
      // this.LotRechargeData = selection;
      this.vehicleList = selection.map(p => {
        return {
          "vehicleId":p.vehicleId,
          "plate":p.plate,
          "terminalNo":p.terminalNo,
          "terminalType":p.terType,
          "groupId":p.groupId,
        }
      });
    },
    //点击授权
    recharge(item) {
      this.RechargeModel = true;//开启弹窗
      this.RechargeData = item.row;
      // this.vehicleList = [{
      //   vehicleId: item.row.vehicleId,
      //   plate: item.row.plate,
      //   terminalNo: item.row.terminalNo,
      //   terminalType: item.row.terType,
      //   groupId: item.row.groupId
      // }];
      // this.dialogVisible = true;
    },
    //点击搜索条件、自定义授权、批量授权
    CheckRecharge(type) {//type = 1 为搜索条件，type = 2 为自定义授权，type = 3 为批量授权
      if (type === 1) {//打开搜索条件框
        this.SearchModel = true;
      } else if (type === 2) {//打开自定义授权
        this.DiyRechargeModel = true;
      } else if (type === 3) {//打开批量授权
        if (this.vehicleList.length == 0) {
          this.$message.error("请先选择车辆！");
          return;
        }
        this.dialogVisible = true;
        // this.LotRechargeModel = true;
      }
    },
    //搜索条件返回数据过来
    DataList(search) {
      this.data = search.rows;
      this.tablePage.total = search.total;
      this.searchParams.pageNumber = 1;
      this.tablePage.pageNumber = 1;
    },

    //点击授权子组件关闭
    closeRecharge(model) {
      this.RechargeModel = model;//关闭弹窗
    },
    //批量授权子组件关闭
    LotcloseRecharge(model) {
      this.LotRechargeModel = model;//关闭弹窗
    },
    //自定义授权子组件关闭
    DiycloseRecharge(model) {
      this.DiyRechargeModel = model;//关闭弹窗
    },
    //搜索条件子组件关闭
    closeSearchCriteria(model) {
      this.SearchModel = model;//关闭弹窗
    },

    // 授权组件关闭
    closeDialog(model){
      this.dialogVisible = model;
    }
  },
  mounted() {
    this.queryVehicleExpired(0,0,1);//进来页面先进行table数据的获取
    this.Tips = true;
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.CarContainer {
  height: 100%;
  .filter-pane > label {
    //调整头部组件间的间隔距离
    margin: 0 6px 0 15px;
  }
  .oper-btns {
    position: absolute;
    bottom: 15px;
  }
  .el-header {
    margin: 0 !important;
  }
  .el-main {
    height: calc(100vh - 288px);
    ::v-deep .el-table__row {
      .cell {
        padding-left: 10px;
      }
    }
  }
  .TipsDialog {
    .tips {
      > p > span {
        color: red;
      }
      .el-button {
        width: 90px;
        position: relative;
        left: 75%;
        top: 5px;
      }
    }
  }
  .el-pagination {
    text-align: center;
  }
}
</style>