<template>
  <!-- <div>授权记录</div> -->
  <el-container class="CarRecord">
    <el-header height="auto">
      <div class="filter-pane reportTree">
        <label>车组车辆</label>
        <SelectGroupTree
          @on-select="onSelect"
          @on-vehicle="onConfirm" :multipleProp="{ noGroup: true }"
          @dbClick="dbConfirm"
          class="TreeWidth"
          @clear="ClearSearchParams"
        ></SelectGroupTree>

        <label style="margin-left:5px">选择时间</label>
        <reportDate
          size="small"
          @change="onChoiceDate"
        ></reportDate>
        
        <el-button
          style="font-size: 14px;margin-right: 15px;height:32px;width:90px;margin-left:20px;"
          type="primary"
          icon="ios-search"
          @click="searchOperRecord(1)"
        >搜索</el-button>
      </div>
    </el-header>
    <el-main>
      <el-table
        size="mini"
        stripe
        :data="data"
        v-loading="tableLoading"
        height="calc(100% - 38px)"
      >
        <el-table-column
          prop="groupName"
          label="车组"
        ></el-table-column>
        <el-table-column
          prop="plate"
          label="车牌"
        ></el-table-column>
        <el-table-column
          prop="terminalNo"
          label="终端编号"
        ></el-table-column>
        <el-table-column
          prop="terminalType"
          label="终端类型"
        ></el-table-column>
        <el-table-column
          prop="newExpire"
          label="到期时间"
        ></el-table-column>
        <el-table-column
          prop="authorizationMode"
          label="续费类型"
        ></el-table-column>
        <el-table-column
          prop="authorizationResult"
          label="续费状态"
        >
          <template slot-scope="scope">
						<el-tag
						type="success"
						>{{scope.row.authorizationResult}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="money"
          label="消耗授权币数"
        ></el-table-column>
        <el-table-column
          prop="reason"
          label="备注"
        ></el-table-column>
        <el-table-column
          prop="operTime"
          label="操作人[时间]"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"
      ></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
//搜索接口
import { queryVehicleFreeLog } from '@/api/All-Interface.js'
export default {
  // `name`与路由保持一致
  name: 'CarRecord',
  data(){
    return{
      searchParams:{
        vehicleId:0,
        groupId:0,
        flag:0,
        startTime:'',
        endTime:"",
        pageSize: 20,
        pageNumber: 1,
      },
      data:[],//数据集合
      tableLoading:false,//用于加载数据时的等待状态
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0
      },
    }
  },
  components:{
    reportDate,
    SelectGroupTree,
  },
  methods:{
    onChoiceDate(dates){//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if(dates == null || dates.length == 0 ){
        return  this.$message.warning('请选择正确的时间');
      };
      if(dates.length === 2){
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
      this.tablePage.pageNumber = 1;
      this.tablePage.total = 0;
    },
    //车组车辆搜索框选择
    onSelect(node){
      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.groupId = node.groupId || 0;
      if(node.type == 2){
        this.searchParams.flag = 1;
      }else if(node.type == 1){
        this.searchParams.flag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.searchParams.vehicleId=0;
      this.searchParams.groupId=0;

      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.flag = 1;
      this.searchParams.groupId = node.groupId || 0;
    },
    // 选择车组
    dbConfirm(node) {
      this.searchParams.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.searchParams.flag = 0;
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = 0;
    },
    	//分页改变每页数量
    changePage(current) {
      this.tablePage.pageNumber = current;
			this.searchParams.pageNumber = current;
      this.searchOperRecord(current);
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams(){
      this.searchParams.vehicleId=0;
      this.searchParams.groupId=0;
    },
    //验证是否有输入车组或车辆，是否有选择时间
    verification(){
      if(this.searchParams.groupId =="" || this.searchParams.groupId ==0){
        this.$message.info("请输入或者选择车组车辆");
        return false;
      };
      if(this.searchParams.startTime =="" || this.searchParams.endTime ==0){
        this.$message.info("请选择时间");
        return false;
      };
      return true;
    },
    //点击搜索
    searchOperRecord(current){
      if (!this.verification()) return;//验证是否有输入车组或车辆
      this.tableLoading = true;//用于加载数据 未完成时 的等待状态
      queryVehicleFreeLog({
        ...this.searchParams,
        pageNumber:current,
        _qt:new Date().getTime()
      }).then((res) => {
        this.tablePage.pageNumber = current;
			  this.searchParams.pageNumber = current;
        this.tablePage.total = res.obj.total;
        if(res.flag == 1){
          this.data = res.obj.rows;
          if (this.data.length <= 0) {
            this.$message.info("请求数据为空！");
          }
        }else{
          this.data = [];
          this.$message.error(res.msg);
        }
        this.tableLoading = false;//加载数据完成，则将等待状态解除
      });
    },
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.CarRecord{
  height:100%;
  .el-header{
    margin: 0px;
    .filter-pane{
      position:relative;
      left: -20px;
    }
  }
  .el-main{
    height: calc(100% - 58px);
  }
  .TreeWidth{
    ::v-deep .inline-input{
      width: 220px;
    }
  }
  .el-pagination{
		padding: 10px 5px 0px;
		text-align: center;
	}
}
</style>