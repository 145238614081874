<template>
  <el-dialog
    title="搜索条件"
    :visible="RechargeModel"
    class="ElDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onVisibleChangeModal"
  >
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="车组车辆" class="reportTree">
        <SelectGroupTree
          @on-select="onSelect"
          @on-vehicle="onConfirm" :multipleProp="{ noGroup: true }"
          @dbClick="dbConfirm"
          class="TreeWidth"
        ></SelectGroupTree>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.isState">
          <el-option label="全部" value></el-option>
          <el-option label="已过期" value="1"></el-option>
          <el-option label="正常" value="0">正常</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆状态">
        <el-select v-model="form.vehicleState">
          <el-option label="全部" value></el-option>
          <el-option label="行驶" value="1"></el-option>
          <el-option label="停车" value="2"></el-option>
          <el-option label="离线" value="3"></el-option>
          <el-option label="从未上线" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="即将到期">
        <el-radio-group v-model="form.expireTime" @change="changeRadio">
          <el-radio :label="7">7天</el-radio>
          <el-radio :label="30">30天</el-radio>
          <el-radio :label="-1">自定义</el-radio>
        </el-radio-group>
        <el-input
          v-model="expireTimeBySelf"
          v-if="expireTimeInput"
          :maxlength="6"
          placeholder="请输入自定义天数"
          style="width:139px;height:30px"
        ></el-input>
      </el-form-item>
      <el-form-item label="安装时间">
        <el-date-picker
          v-model="form.installDateStart"
          type="datetime"
          placeholder="开始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
        <span
          style="
					display:inline-block;
					width:30px;
					text-align:center;
				"
        >—</span>
        <el-date-picker
          v-model="form.installDateEnd"
          type="datetime"
          placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="激活时间">
        <el-date-picker
          v-model="form.activationTmeStart"
          type="datetime"
          placeholder="开始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
        <span
          style="
					display:inline-block;
					width:30px;
					text-align:center;
				"
        >—</span>
        <el-date-picker
          v-model="form.activationTmeEnd"
          type="datetime"
          placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="离线时长">
        <span>（天）&lt;=</span>
        <el-input class="DayInput" v-model="form.offlineTime" :maxlength="6"></el-input>
      </el-form-item>
      <el-form-item>
        <div class="FormButton">
          <el-button @click="onVisibleChangeModal">取消</el-button>
          <el-button type="primary" @click="checkOK">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
//查询接口
import { queryVehicleExpired } from '@/api/All-Interface.js';
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
export default {
  name: 'Recharge',
  data() {
    return {
      form: {
        isState: "",//状态 1 为已过期，0为正常，默认全部
        vehicleState: "",//车辆状态
        expireTime: 31,//即将到期
        installDateStart: "",//安装开始时间
        installDateEnd: "",//安装结束时间
        activationTmeStart: "",//激活开始时间
        activationTmeEnd: "",//激活结束时间
        offlineTime: 0,//离线时长
        pageSize: 50,
        pageNumber: 1,
        flag: 0,
        groupId: 0,
        vehicleId: 0,
        days: 0,
      },
      expireTimeInput: false,//自定义输入即将到期时间显示
      expireTimeBySelf: '',//自定义输入即将到期时间
    };
  },
  components: {
    SelectGroupTree
  },
  props: {
    RechargeModel: false,
    Cardata: {},
  },
  methods: {
    //车组车辆搜索框选择
    onSelect(node) {
      this.form.vehicleId = node.vehicleId || 0;
      this.form.groupId = node.groupId || 0;
      if (node.type == 2) {
        this.form.flag = 1;
      } else if (node.type == 1) {
        this.form.flag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.form.vehicleId = 0;
      this.form.groupId = 0;

      this.form.vehicleId = node.vehicleId || 0;
      this.form.flag = 1;
      this.searchParams.groupId = node.groupId || '';
    },
    // 选择车组
    dbConfirm(node) {
      this.form.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.form.flag = 0;
      this.form.groupId = node.groupId;
      this.form.vehicleId = 0;
    },
    //点击确认授权
    checkOK() {
      this.form.expireTime == -1 ? this.form.expireTime = this.expireTimeBySelf : this.form.expireTime;
      let newexpireTime = this.form.expireTime * 24 * 60 * 60;
      queryVehicleExpired({
        ...this.form,
        expireTime: newexpireTime,
        _qt: new Date().getTime()
      }).then((res) => {
        if (res.flag == 1) {
          this.$emit('SearchData', res);
        } else {
          this.$message.error(res.msg);
        }
        this.onVisibleChangeModal();//关闭弹窗
      });
    },
    //改变单选按钮 为 -1 时为自定义，释放自定义输入框
    changeRadio(item) {
      if (item == "-1") {
        this.expireTimeInput = true;
      } else {
        this.expireTimeInput = false;
      }
    },
    //关闭对话框
    onVisibleChangeModal(item) {
      this.$emit('close', false);
    },
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportTreeAndData.scss';
.ElDialog {
  ::v-deep .el-dialog {
    width: 500px;
    .el-dialog__header {
      padding: 5px 20px;
      background-color: #212d3e;
      .el-dialog__title {
        color: #ffffff;
        font-size: 15px;
      }
      .el-dialog__headerbtn {
        top: 9px;
        .el-icon {
          font-size: 18px;
          color: #ffffff;
        }
      }
    }
    .el-dialog__body {
      padding: 30px 0px 70px 35px;
      .el-form {
        .el-select {
          width: 330px;
        }
        .el-radio {
          margin-right: 10px;
          .el-radio__inner {
            width: 12px;
            height: 12px;
            position: relative;
            top: -1px;
            right: -2px;
          }
        }
        .el-date-editor {
          width: 150px;
          .el-input__inner {
            font-size: 12px;
            padding: 0 19px;
          }
          .el-input__prefix {
            left: 0;
            .el-input__icon {
              width: 20px;
            }
          }
          .el-input__suffix {
            right: 0;
          }
        }
        .DayInput {
          width: 260px;
          margin-left: 12px;
        }
        .FormButton {
          position: absolute;
          right: 55px;
          bottom: -60px;
          .el-button {
            width: 90px;
          }
        }
      }
    }
  }
  .TreeWidth {
    ::v-deep .inline-input {
      width: 285px;
    }
    > span {
      border-left: 1px solid #dcdfe6 !important;
      ::v-deep .el-button {
        padding: 0 15px;
      }
    }
  }
  .reportTree {
    ::v-deep .select-tree {
      //覆盖车组车辆样式
      > span {
        border-left: 1px solid #dcdfe6;
        padding: 0;
        height: 30px;
        position: relative;
        top: 1px;
      }
      .el-popover__reference-wrapper {
        .el-button {
          display: inline-block;
          width: 43px;
          height: 30px;
          position: absolute;
          top: 0;
          border: none;
        }
      }
    }
  }
}
</style>