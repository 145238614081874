<template>
  <!-- <div>授权币划拨记录</div> -->
  <el-container class="CarSpendMoneyRecord">
    <el-header height="auto">
      <div class="filter-pane">
        <label>用户</label>
        <el-select
          v-model="userName"
          filterable
          placeholder="请搜索或选择用户"
          @focus="CLickUser(searchParams.userId)"
          @change="ChangeUser"
          clearable
          @clear="clear"
          style="width:220px"
        >
          <el-option
            v-for="item in searchVehicleDataList"
            :key="item.userId"
            :label="item.name"
            :value="item"
          ><span>{{`账号：${item.name}， 公司名：${item.corpName}`}}</span>
          </el-option>
        </el-select>

        <label>选择时间</label>
        <reportDate
          size="small"
          @change="onChoiceDate"
        ></reportDate>
        
        <el-button
          style="font-size: 14px;margin-right: 15px;height:32px;width:90px;margin-left:20px;"
          type="primary"
          icon="ios-search"
          @click="searchOperRecord"
        >查询</el-button>
      </div>
    </el-header>
    <el-main>
      <el-table
        size="mini"
        stripe
        :data="data"
        v-loading="tableLoading"
        height="calc(100% - 45px)"
      >
        <el-table-column
          prop="userBCorpName"
          label="公司名"
        ></el-table-column>
        <el-table-column
          prop="userB"
          label="账号"
        ></el-table-column>
        <el-table-column
          prop="moneyStr"
          label="划拨授权币数"
        ></el-table-column>
        <el-table-column
          prop="operTime"
          label="操作人[划拨时间]"
        ></el-table-column>
        <el-table-column
          prop="moneyAfterStr"
          label="可使用授权币数"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"
      ></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//搜索接口
import { queryRechargeLog , searchUserLevel} from '@/api/All-Interface.js'
export default {
  // `name`与路由保持一致
  name: 'CarSpendMoneyRecord',
  data(){
    return{
      searchParams:{
        userId:"",
        startTime:'',
        endTime:"",
      },
      data:[],//数据集合
      searchVehicleDataList:[],
      userName:"",

      tableLoading:false,//用于加载数据时的等待状态
      tablePage: {
        //分页
        pageSizeOpts: [20, 50, 100],
        pageNumber: 1,
        pageSize: 20,
        total: 0
      },
    }
  },
  props:{
    userId:"",
  },
  components:{
    reportDate,
  },
  methods:{
    onChoiceDate( dates){//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if(dates.length === 0) return  this.$message.warning('请选择正确的时间');
      if(dates.length === 2){
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
    },
    //分页改变每页数量
    pageSizeChange(pageSize) {
      this.tablePage.pageSize = pageSize;
      this.searchParams.pageSize = pageSize;
      this.changePage(1);
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.searchOperRecord();
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.searchOperRecord(current);
    },

    //点击用户选择
    CLickUser(){
      this.searchVehicleDataList=[];
      searchUserLevel({
        keyword: this.userName
      }).then((res) => {
        this.searchVehicleDataList = res.obj
      });
    },
    ChangeUser(row){
      this.searchParams.userId = row.userId;
      this.userName = row.corpName
    },
    clear(){
      //初始化
      this.searchParams.userId= "";
      this.userName="";
    },

    //点击搜索
    searchOperRecord(current){
      this.tableLoading = true;//用于加载数据 未完成时 的等待状态
      if(this.searchParams.userId ===""){ //如果不选择用户，则默认输出当前账号下的所有授权记录
        this.searchParams.userId = this.userId;
      };
      queryRechargeLog({
        ...this.searchParams,
      }).then((res) => {
        if(res.flag == 1){
          this.data = res.obj.list;
          this.tablePage.total = res.obj.list.length;
          this.tablePage.pageNumber = current;
          this.searchParams.pageNumber = current;
          if (this.data.length <= 0) {
            this.$message.info("请求数据为空！");
          }
        }else{
          this.data = [];
          this.$message.error(res.msg);
        }
        this.tableLoading = false;//加载数据完成，则将等待状态解除
      });
    },
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.CarSpendMoneyRecord{
  height: 100%;
  .el-header{
    margin: 0px;
    .filter-pane{
      position:relative;
      left: -20px;
      > label{ //调整头部组件间的间隔距离
  	    margin: 0 6px 0 15px;
      }
    }
  }
  .el-main{
    height: calc(100% - 48px);
    padding-bottom: 4px;
  }
  .el-pagination{
    padding: 12px 5px 5px;
    text-align: center;
  }
  .TreeWidth{
    ::v-deep .inline-input{
      width: 220px;
    }
  }
}
</style>