<template>
  <!-- <div>授权币划拨</div> -->
  <el-container class="CarSpendMoney">
    <el-header height="auto">
      <div class="filter-pane">
        <label>划拨用户</label>
        <el-select
          v-model="userName"
          filterable
          placeholder="请搜索或选择用户"
          @focus="CLickUser(searchParams.userId)"
          @change="ChangeUser"
          clearable
          @clear="clear"
          style="width:220px"
        ><el-option
            v-for="item in searchVehicleDataList"
            :key="item.userId"
            :label="item.name"
            :value="item"
          ><span>{{`账号：${item.name}， 公司名：${item.corpName}`}}</span>
          </el-option>
        </el-select>

        <label style="width:100px">划拨授权币数</label>
        <el-input
					style="width:220px"
					v-model="searchParams.chargeNum"
				></el-input>
        <el-button
          style="font-size: 14px;margin-right: 15px;height:32px;width:90px;margin-left:20px;"
          type="primary"
          icon="ios-search"
          @click="searchOperRecord(1)"
        >划拨</el-button>
      </div>
    </el-header>
    <el-main>
      <el-table
        size="mini"
        stripe
        :data="data"
        v-loading="tableLoading"
        height="calc(100% - 45px)"
      >
        <el-table-column
          prop="userBCorpName"
          label="公司名"
        ></el-table-column>
        <el-table-column
          prop="userB"
          label="账号"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="划拨授权币数"
        ></el-table-column>
        <el-table-column
          prop="operTime"
          label="操作人[划拨时间]"
        ></el-table-column>
        <el-table-column
          prop="moneyAfter"
          label="可用授权币数"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"
      ></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
//选择时间
import DataRangePicker from "@/components/Date/DataRangePicker";
//搜索接口
import { rechargeNum ,searchUserLevel } from '@/api/All-Interface.js'
export default {
  // `name`与路由保持一致
  name: 'CarSpendMoney',
  data(){
    return{
      tableLoading:false,//用于加载数据时的等待状态
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0
      },
			//授权币划拨数量 和用户
			searchParams:{
				chargeNum:0,
				userId:"",
			},
      data:[],//table 的数据集合
      searchVehicleDataList:[],
      userName:"",
    }
  },
  components:{
    DataRangePicker,
  },
  methods:{
    onChoiceDate(type, dates){//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if(dates.length === 0) return  this.$message.warning('请选择正确的时间');
      if(dates.length === 2){
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.searchOperRecord(current);
    },
    
    //点击用户选择
    CLickUser(){
      
      this.searchVehicleDataList=[];
      searchUserLevel({
        keyword: this.userName
      }).then((res) => {
        this.searchVehicleDataList = res.obj
      });
    },
    ChangeUser(row){
      this.searchParams.userId = row.userId;
      this.userName = row.corpName
    },
    clear(){
      //初始化
      this.searchParams.chargeNum = 0;
      this.searchParams.userId = 0;
      this.userName="";
    },


    //点击划拨
    searchOperRecord(current){
      //判断授权币是否为 0,用户是否为空
      if(this.searchParams.chargeNum == 0){
        this.$message.error("授权时，授权币不能为 0 ！");
        return
      }
      if(this.userName.trim() == "") return this.$message.warning("请输入需划拨的用户");
      //再次确认是否进行划拨
      this.$confirm(
        `确定要选中的用户 <span style="color: #ff9900;">
          ${this.userName}
        </span>，划拨
        <span style="color: #ff9900;">
          ${this.searchParams.chargeNum}
        </span>授权币？`
        ,'提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type:'warning',
          center:true
      }).then(() =>{
        this.tableLoading = true;//用于加载数据 未完成时 的等待状态
        rechargeNum({ //调用接口划拨授权币
          ...this.searchParams,
          pageNumber:current
        }).then((res) => {
          if(res.flag == 1){
            this.data.unshift(res.obj);
            this.tablePage.pageNumber = current;
            this.searchParams.pageNumber = current;
            this.tablePage.total = this.data.length;
            this.$emit('UserInfo');//划拨币出去后，更新头部的账户信息
            if (this.data.length <= 0) {
              this.$message.info("请求数据为空！");
            }
          }else{
            this.data = [];
            this.$message.error(res.msg);
          }
          this.tableLoading = false;//加载数据完成，则将等待状态解除
        });
      }).catch(() => {
        this.$message({
          type:'info',
          message:'已经取消'
        });
        return;
      });
      
    },
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
.CarSpendMoney{
  height: 100%;
  .el-header{
    margin: 0px;
    .filter-pane > label{ //调整头部组件间的间隔距离
      margin: 0 6px 0 0px;
    }
  }
  .el-main{
    height: calc(100% - 58px);
    padding-bottom: 4px;
  }
  .el-pagination{
    padding: 12px 5px 5px;
    text-align: center;
  }
  .TreeWidth{
    ::v-deep .inline-input{
      width: 220px;
    }
  }
}
</style>