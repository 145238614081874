<template>
  <el-dialog
    title="批量授权"
    :visible="RechargeModel"
    class="ElDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onVisibleChangeModal"
  >
    <el-descriptions :column="1" :colon="false" labelClassName="DesCLass">
      <el-descriptions-item
        label="授权“GPRS-部标”车辆数"
      >{{searchParams.GPRSLength}}台</el-descriptions-item>
      <el-descriptions-item
        label="授权“GB-1078”车辆数"
      >{{searchParams.GB1078Length}}台</el-descriptions-item>
      <el-descriptions-item label="授权总车辆数">{{searchParams.allLength}}台</el-descriptions-item>
      <el-descriptions-item label="授权时长">
        <template>
          <el-radio v-model="radio" label="3">3个月</el-radio>
          <el-radio v-model="radio" label="6">6个月</el-radio>
          <el-radio v-model="radio" label="12">1年</el-radio>
          <el-radio v-model="radio" label="36">3年</el-radio>
          <el-radio v-model="radio" label="60">5年</el-radio>
        </template>
      </el-descriptions-item>
    </el-descriptions>
    <div class="money">
      消耗
      <span style="color:#FF4F01">{{searchParams.radioMoney}}</span> 个授权币
      <el-button type="primary" class="check" @click="checkOK">确定授权</el-button>
    </div>
  </el-dialog>
</template>
<script>
//授权接口
import { vehicleRecharge, batchRecharge } from '@/api/All-Interface.js';
export default {
  name: 'Recharge',
  data() {
    return {
      radio: "3",//选择的月份
      searchParams: {//接口请求参数
        radioMoney: 0,
        GPRSLength: 0,//GPRS-部标 数量
        GB1078Length: 0,//GB-1078 数量
        allLength: 0,//总数量
        gvids: "",//车组 , 车辆以 ; 分割
      }
    };
  },
  props: {
    RechargeModel: false,
    Cardata: {},
    DiyData: "",//判断是否是由自定义授权传值过来
  },
  watch: {
    RechargeModel: {
      handler(val) {//开启弹窗时，计算设备数量，并且规范数据
        if (this.DiyData != "" && this.DiyData != undefined) { //DiyData 有值，说明当前是由自定义授权传过来的授权数据
          this.searchParams={
            "GPRSLength":this.DiyData.obj.gprsCount,
            "GB1078Length":this.DiyData.obj.gb1078Count,
            "allLength":this.DiyData.obj.gprsCount + this.DiyData.obj.gb1078Count,
            "radioMoney":this.DiyData.obj.gprsCount * 3 + this.DiyData.obj.gb1078Count * 10,
            "gvids":this.DiyData.obj.gvids + ";",
          };
          this.radio = "3";
        } else {
          let GPRSs = this.Cardata.filter(p => p.terType && p.terType == "GPRS-部标");
          let GB1078 = this.Cardata.filter(p => p.terType && p.terType == "GB-1078");
          let itemArr = [];
          GPRSs.forEach((val) => {
            itemArr.push(val.groupId + "," + val.vehicleId);
          });
          GB1078.forEach((val) => {
            itemArr.push(val.groupId + "," + val.vehicleId);
          });
          this.searchParams.GPRSLength = GPRSs.length;
          this.searchParams.GB1078Length = GB1078.length;
          this.searchParams.allLength = itemArr.length;
          this.searchParams.gvids = itemArr.join(";");
          this.radio = "3";
          this.searchParams.radioMoney = this.searchParams.GPRSLength * 3 + this.searchParams.GB1078Length * 10;
        }
      }
    },
    radio: { //监控选择月份
      handler(val) {
        let numb = Number(val) / 12;
        if (numb >= 1) {
          this.searchParams.radioMoney = this.searchParams.GPRSLength * numb * 10 + this.searchParams.GB1078Length * numb * 25;
        } else {
          if (Number(val) == 3) {
            this.searchParams.radioMoney = this.searchParams.GPRSLength * 3 + this.searchParams.GB1078Length * 10;
          } else {
            this.searchParams.radioMoney = this.searchParams.GPRSLength * 6 + this.searchParams.GB1078Length * 20;
          }
        }
      }
    }
  },
  methods: {
    //点击确认授权
    checkOK() {
      if (this.DiyData != "") { //自定义授权
        //直接在这里进行授权，自定义授权弹窗已经关闭
        batchRecharge({
          addMonth: this.searchParams.radioMoney,
          terminalNo: this.searchParams.gvids
        }).then((res) => {
          if (res.flag == 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        vehicleRecharge({ // 批量授权
          ...this.searchParams,
          _qt: new Date().getTime()
        }).then((res) => {
          if (res.flag == 1) {
            this.$message.info(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      // this.RechargeModel =false;
      this.onVisibleChangeModal();//关闭弹窗
    },
    //关闭对话框
    onVisibleChangeModal() {
      this.$emit('close', false);
    },
  }
}
</script>
<style lang="scss" scoped>
.ElDialog {
  ::v-deep .el-dialog {
    width: 490px;
    height: 390px;
    .el-dialog__header {
      padding: 5px 20px;
      background-color: #212d3e;
      .el-dialog__title {
        color: #ffffff;
        font-size: 15px;
      }
      .el-dialog__headerbtn {
        top: 9px;
        .el-icon {
          font-size: 18px;
          color: #ffffff;
        }
      }
    }
    .el-dialog__body {
      height: calc(100% - 34px);
      padding: 30px 0px 30px 35px;
      .el-descriptions-item {
        padding-bottom: 18px;
        .DesCLass {
          color: #a4a4a4;
          font-size: 14px;
          display: inline-block;
          width: 170px;
        }
        .el-descriptions-item__content {
          line-height: 28px;
          position: relative;
          font-size: 14px;
          top: -5px;
          display: inline-block;
          width: 270px;
          .el-radio {
            position: relative;
            .el-radio__label {
              display: inline-block;
              width: 35px;
            }
            .el-radio__inner {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .money {
        width: 420px;
        border: 1px solid #ffc091;
        position: absolute;
        left: calc(50% - 210px);
        line-height: 28px;
        padding: 0 20px;
        background-color: #fff8ef;
        font-weight: 500;
        .check {
          position: absolute;
          right: 0;
          top: 52px;
        }
      }
    }
  }
}
</style>