<template>
	<div>
		<el-dialog
			title="自定义授权"
			:visible="RechargeModel"
			class="ElDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			@close="onVisibleChangeModal"
		>
			<div class="money">
				请输入需要授权的终端编号，并用英文逗号隔开！（单次授权不能超过500辆）
			</div>
			<div>
				<el-input
					type="textarea"
					placeholder="请填入终端编号"
					v-model="textarea"
					:autosize="{ minRows: 10, maxRows: 20 }"
					class="textareaInput"
				></el-input>
			</div>
			<div class="button">
				<el-button
					@click="onVisibleChangeModal"
				>取消</el-button>
				<el-button
					type="primary"
					@click="checkOK()"
				>确定</el-button>
			</div>
		</el-dialog>
		<!-- 批量导入 -->
		<LotRecharge
			:RechargeModel="LotRechargeModel"
			@close="LotcloseRecharge"
			:DiyData="textOBJ"
		></LotRecharge>
		<!-- 授权弹窗 -->
    <AuthCoin
      :dialogVisible="dialogVisible"
      @input="closeDialog"
      :vehicleList="vehicleList"
    ></AuthCoin>
	</div>
</template>

<script>
//授权接口
import { vehicleRecharge , differDevType} from '@/api/All-Interface.js';
//引入批量导入
import LotRecharge from '@/views/VehicleAuthorization/LotRecharge';
// 引入授权弹窗
import AuthCoin from "@/components/AuthCoin/AuthCoin.vue";
export default {
	name:'Recharge',
	data(){
		return{
			textarea:'',//输入终端编号的文本域

			//批量授权的子组件
			LotRechargeModel:false,//开启子组件弹窗，默认关闭
			LotRechargeData:[],//传给子组件数据
			textOBJ:{},//传给子组件数据

			//授权弹窗开关
      dialogVisible:false,
      vehicleList:[],// 授权数据集合
		}
	},
	components:{
		LotRecharge,
		AuthCoin
	},
	props:{
		RechargeModel:false,
		Cardata:{},
	},
	methods:{
		//关闭对话框
		onVisibleChangeModal(){
			this.textarea="";
			this.$emit('close',false);
		},
		//点击确认
		checkOK(){
			if(this.textarea ==""){
				this.$message.info("请输入终端编号！");
			}else{
				let m_renewLength = this.textarea.split(",").length;
				if (m_renewLength > 500) {//判断数量是否大于 500
					this.$confirm('一次性授权不能超过500条！','提示',{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					});
					return
        };
				differDevType({
					terminalNos:this.textarea
				}).then((res) => {
					if(res.flag ==1){
						this.textOBJ = res;
						this.textOBJ.obj.gvids=this.textarea;
						this.onVisibleChangeModal();
						this.LotRechargeModel = true;
					}else{
						this.$message.info(res.msg);
						this.onVisibleChangeModal();
            return;
					}
				});
			}
		},
		//批量授权子组件关闭
		LotcloseRecharge(model){
			this.LotRechargeModel = model;//关闭弹窗
		},
		// 授权组件关闭
    closeDialog(model){
      this.dialogVisible = model;
    }
	}
}
</script>
<style lang="scss" scoped>
.ElDialog{
	::v-deep .el-dialog{
		width: 600px;
		.el-dialog__header{
			padding: 5px 20px;
			background-color: #212D3E;
			.el-dialog__title{
				color: #ffffff;
				font-size: 15px;
			}
			.el-dialog__headerbtn{
				top: 9px;
				.el-icon{
					font-size: 18px;
					color: #ffffff;
				}
			}
		}
		.el-dialog__body{
			height: calc(100% - 34px);
			padding: 20px 30px 130px 35px;
			position: static;
			.money{
				width: 560px;
				border: 1px solid #FFC091;
				position: absolute;
				left: calc(50% - 280px);
				line-height: 28px;
				padding: 0 20px;
				background-color: #FFF8EF;
				font-weight: 500;
				font-size: 13px;
				color:#f88d3d;
			}
			.textareaInput{
				width: 560px;
				position: relative;
				top: 50px;
				right: 15px;
			}
			.button{
				position: absolute;
				bottom: 25px;
				right: 20px;
				.el-button{
					width: 80px;
					height: 32px;
				}
			}
		}
	}
}
</style>