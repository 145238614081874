<template>
	<el-dialog
		title="授权"
		:visible="RechargeModel"
		class="ElDialog"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@close="onVisibleChangeModal"
	>
		<el-descriptions
			:column="1"
			:colon="false"
			labelClassName="DesCLass"
		>
			<el-descriptions-item label="车牌">{{Cardata.plate}}</el-descriptions-item>
			<el-descriptions-item label="到期时间">{{Cardata.expireDate}}</el-descriptions-item>
			<el-descriptions-item label="授权时长">
				<template>
						<el-radio v-model="radio" label="3">3个月</el-radio>
						<el-radio v-model="radio" label="6">6个月</el-radio>
						<el-radio v-model="radio" label="12">1年</el-radio>
						<el-radio v-model="radio" label="36">3年</el-radio>
						<el-radio v-model="radio" label="60">5年</el-radio>
				</template>
			</el-descriptions-item>
		</el-descriptions>
		<div class="money">
			消耗 <span style="color:#FF4F01">{{radioMoney}}</span> 个授权币
			<el-button
				type="primary"
				class="check"
				@click="checkOK"
			>确定授权</el-button>
		</div>
	</el-dialog>
</template>
<script>
//授权接口
import { vehicleRecharge } from '@/api/All-Interface.js'
export default {
	name:'Recharge',
	data(){
		return{
			radio:"3",//选择的月份
			radioMoney:"",
		}
	},
	props:{
		RechargeModel:false,
		Cardata:{},
	},
	watch:{
		RechargeModel:{
			handler(val){
				if(this.Cardata.terType === "GB-1078" || this.Cardata.terType === "GF-1078"){
					this.radio="3";
					this.radioMoney = 10;
				}else if(this.Cardata.terType === "GPRS-部标"){
					this.radio="3";
					this.radioMoney = 3;
				}
			}
		},
		radio:{ //监控选择月份
			handler(val){
				let numb = Number(val)/12;
				if(numb>=1){
					if(this.Cardata.terType === "GPRS-部标"){
						this.radioMoney=numb*10;
					}else if(this.Cardata.terType === "GB-1078" || this.Cardata.terType === "GF-1078"){
						this.radioMoney=numb*25;
					}
				}else{
					if(this.Cardata.terType === "GPRS-部标"){
						this.radioMoney =Number(val);
					}else if(this.Cardata.terType === "GB-1078" || this.Cardata.terType === "GF-1078"){
						Number(val)==3?this.radioMoney=10:this.radioMoney=20
					}
				}
			}
		}
	},
	methods:{
		//点击确认授权
		checkOK(){
			vehicleRecharge({
				addMonth:this.radioMoney,
				gvids:this.Cardata.groupId + ','+ this.Cardata.vehicleId+";",
				_qt:new Date().getTime()
			}).then((res) => {
				if(res.flag == 1){
					this.$message.info(res.msg);
				}else{
					this.$message.error(res.msg);
				}
			})
			this.onVisibleChangeModal();
		},
		//关闭对话框
		onVisibleChangeModal(){
			this.$emit('close',false);
		},
	},
}
</script>
<style lang="scss" scoped>
.ElDialog{
	::v-deep .el-dialog{
		width: 490px;
		height: 340px;
		.el-dialog__header{
			padding: 5px 20px;
			background-color: #212D3E;
			.el-dialog__title{
				color: #ffffff;
				font-size: 15px;
			}
			.el-dialog__headerbtn{
				top: 9px;
				.el-icon{
					font-size: 18px;
					color: #ffffff;
				}
			}
		}
		.el-dialog__body{
			height: calc(100% - 34px);
			padding: 30px 30px 30px 35px;
			.el-descriptions-item{
				padding-bottom: 18px;
				.DesCLass{
					color: #A4A4A4;
					font-size: 14px;
					display: inline-block;
					width: 70px;
				}
				.el-descriptions-item__content{
					line-height: 28px;
					position: relative;
					font-size: 14px;
					top: -5px;
					.el-radio{
						position: relative;
						left: 10px;
						.el-radio__label{
							display: inline-block;
							width: 20px;
							margin-right: 20px;
						}
						.el-radio__inner{
							width:12px;
							height: 12px;
						}
					}
				}
			}
			.money{
				width: 400px;
				border: 1px solid #FFC091;
				position: absolute;
				left: calc(50% - 200px);
				line-height: 28px;
				padding: 0 20px;
				background-color: #FFF8EF;
				font-weight: 500;
				.check{
					position: absolute;
					right: 0;
					top: 52px;
				}
			}
			
		}
	}
}
</style>